<template>
  <div class="flex justify-center flex-col items-center cursor-default gap-1">
    <VTooltip max-width="300" v-for="benefit in benefits" :key="benefit.text">
      <template #activator="{ props }">
        <div v-bind="props" class="inline-flex items-center gap-2 w-[270px] border-b-[2px] hover:border-primary hover:text-primary border-dashed	">
          <VIcon size="18" color="primaryAlt" icon="mdi-check-outline" />
          <span v-html="benefit.text" />
        </div>
      </template>
      <span v-html="benefit.tooltip" />
    </VTooltip>
  </div>
</template>

<script lang="ts" setup>
const benefits = [
  {
    text: "Detecção avançada de erros",
    tooltip: `Veja marcações avançadas de erros no seu texto, como repetição de palavras, uso de conectivos, avaliação de frases e parágrafos, e muito mais.`,
  },
  {
    text: "Comentários detalhados",
    tooltip: `Em todas correções você receberá um comentário detalhado explicando o que você fez de certo e errado, e como melhorar.`,
  },
  {
    text: "Modo Redação Guiada",
    tooltip: `Não sabe como começar sua redação? Use o <b>Modo Redação Guiada</b> e siga um guia passo a passo para escrever sua redação.`,
  },
  {
    text: "Métricas nota 1000",
    tooltip: `Veja como sua redação se compara com as <b>Redações nota 1000</b> das edições passadas do ENEM e saiba o que melhorar.`,
  },
  {
    text: "Curso <b>Redação de Sucesso</b>",
    tooltip: `Aulas práticas e objetivas para você aprender a escrever uma redação sobre qualquer tema do zero.`,
  },
  {
    text: "Correção instantânea",
    tooltip: `Corrija suas redação instantaneamente sem fila de espera.`,
  },
];
</script>
