<template>
  <div class="flex flex-col items-center">
    <img class="w-8 h-8" src="/images/icons/star.png" />

    <h4 class="text-xl font-bold mt-2">
      coRedação <span class="text-primary">PREMIUM</span>
    </h4>
    <p>
      A partir de 12 x {{formatCurrency(fromPrice)}}/mês
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useConfig } from '~/domains/checkout/composables/useConfig';
import { formatCurrency } from '~/domains/global/libs/formatCurrency';

const { fromPrice } = useConfig()
</script>